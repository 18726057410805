<template>
    <div></div>
    <!-- <div >双鱼座</div> -->
</template>
<script>
export default {
    name:'Pisces',
    mounted: function() {
        this.clicknavigateTo();
    },
    methods: {
        clicknavigateTo: function() {
            window.location.href='https://www.twelwestars.com/Tasting/goods/videoExplain?id=16&store_id=0&chk=&pcode=202008142783';
            //this.$router.push({ path:'/Tasting/goods/videoExplain?id=27&store_id=0&chk=&pcode=202008142778'})
        },
    }
}
</script>